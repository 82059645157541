import type { Environment } from './types';
import { sharedJuniperEnv, sharedDevEnv, sharedDevAuEnv } from './shared';

export const env: Environment = {
  ...sharedDevEnv,
  ...sharedJuniperEnv,
  ...sharedDevAuEnv,
  adminAppUrl: 'https://dev.admin.myjuniper.com',
  logoUrl: 'https://assets.myjuniper.com/juniper-logo.svg',
};
